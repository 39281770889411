<template>
  <div class="tw-mb-10" v-if="dataLoaded">
    <!-- Take Survey Section -->
    <v-row class="tw-pb-10 text-left" v-if="isOwner || isOrganizer">
      <v-col class="tw-px-9" v-if="survey">
        <h3 class="tw-text-25 tw-text-dark-green tw-font-semibold">
          Your trip survey
        </h3>
        <p class="tw-text-light-grey tw-text-base tw-leading-22">
          You can add or remove questions to your survey until a response is
          submitted.
        </p>
        <v-expansion-panels class="tw-rounded-md tw-mb-6">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="j-panel-header">
                <div
                  class="tw-text-dark-green tw-text-base tw-font-semibold tw-mx-auto"
                >
                  View/Edit Survey
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="j-panel-content">
              <div
                v-for="(question, index) in survey.questions"
                :key="index"
                class="tw-px-6 tw-pb-4"
              >
                <div v-if="question.text === 'What date(s) work best?'">
                  <p class="j-invitation-label tw-mb-1">{{ question.text }}</p>
                  <div
                    class="tw-bg-lighter-grey tw-rounded-md tw-py-6 tw-px-5 tw-mb-5"
                  >
                    <p
                      v-for="(option, index) in sortedDates(
                        question.survey_options
                      )"
                      :key="index"
                      class="j-invitation-paragraph tw-mb-0"
                    >
                      {{ getFormattedDateRangeFromString(option) }}
                    </p>
                  </div>
                </div>
                <div v-else>
                  <p class="j-invitation-label tw-mb-1">{{ question.text }}</p>
                  <div
                    class="tw-bg-lighter-grey tw-rounded-md tw-py-6 tw-px-5 tw-mb-5"
                  >
                    <p
                      v-for="(option, index) in question.survey_options"
                      :key="index"
                      class="j-invitation-paragraph tw-mb-0"
                    >
                      {{ option.text }}
                    </p>
                  </div>
                </div>
              </div>
              <router-link
                class="tw-bg-dark-green tw-text-white tw-p-5 tw-text-center tw-rounded-b-md tw-font-semibold tw-block tw-w-full"
                :to="{
                  name: 'SurveyEditRouteForm',
                  params: {
                    surveyId: $route.params.surveyId
                  }
                }"
              >
                Edit Survey
              </router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <div
      class="tw-bg-separator-grey tw-w-full tw-h-2"
      v-if="(isOwner || isOrganizer) && !currentUserHasResponded"
    ></div>
    <div v-if="!currentUserHasResponded" class="tw-py-10 tw-px-9">
      <h3
        class="tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-4"
      >
        Your Voice Counts Too
      </h3>
      <p class="tw-text-left tw-text-light-grey">
        There's a survey with your name on it. Your responses will be included
        in the results.
      </p>
      <v-btn
        x-large
        rounded
        block
        depressed
        class="j-btn tw-bg-chartreuse tw-text-dark-green tw-tracking-normal"
        @click="
          goTo({
            name: 'SurveyRoute',
            params: { surveyId: $route.params.surveyId }
          })
        "
        >Take Survey</v-btn
      >
    </div>
    <div
      class="tw-bg-separator-grey tw-w-full tw-h-2"
      v-if="isOwner || isOrganizer || !currentUserHasResponded"
    ></div>
    <!-- Survey Responses Section -->
    <div
      class="tw-px-9 tw-py-12 tw-rounded-md"
      :class="{ 'tw--mt-8': !(isOwner || isOrganizer) }"
    >
      <h3
        class="tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-4"
      >
        Survey Responses
      </h3>
      <v-chip-group>
        <v-chip
          v-for="(user, i) in tripUsers"
          :key="i"
          :value="user"
          :ripple="false"
          ><UserAvatar
            :user="user"
            size="56"
            :class="{ 'greyed-out': !userCompletedSurvey(user.id) }" />
          <img
            v-if="
              userIdsHaveResponded && userIdsHaveResponded.includes(user.id)
            "
            class="tw-absolute tw-w-4 tw-h-4 tw-bottom-0 tw-right-3"
            src="@/assets/svgs/surveys/avatar-checkmark.svg"
            alt="Checkmark"
        /></v-chip>
      </v-chip-group>
    </div>
    <div class="tw-bg-separator-grey tw-w-full tw-h-2"></div>
    <!-- Copy/Share Link for Survey Page -->
    <!-- <div v-if="survey" class="tw-mb-12">
      <h3
        class="tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-4"
      >
        Share Survey
      </h3>
      <p class="tw-text-left tw-text-light-grey">
        Share the survey link to remind them.
      </p>
      <button
        class="tw-bg-white tw-rounded-md tw-shadow-custom tw-p-3 tw-font-semibold tw-flex tw-flex-row tw-items-center"
        @click="copyLink"
      >
        <span class="tw-mr-6" v-html="copyBtnLabel"></span>
        <v-icon size="16">mdi-content-copy</v-icon>
      </button>
    </div> -->

    <!-- Survey Results -->
    <div
      class="tw-px-9 tw-py-12 tw-rounded-md"
      v-if="surveyData && surveyData.questions"
    >
      <h3
        class="tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-8"
      >
        Survey Results
      </h3>

      <div
        v-for="(question, qIndex) in surveyData.questions"
        :key="qIndex"
        class="tw-rounded-md tw-bg-white tw-p-6 tw-mb-5 tw-shadow-custom"
      >
        <h3 class="tw-text-left tw-font-semibold tw-mb-4">
          {{ question.text }}
        </h3>
        <div v-if="question.text === 'What date(s) work best?'">
          <div
            v-for="(option, oIndex) in sortedDates(question.survey_options)"
            :key="oIndex"
            class="option-result tw-mb-7"
          >
            <div
              class="tw-text-light-grey tw-text-sm tw-flex tw-flex-row tw-justify-between"
            >
              {{ getFormattedDateRangeFromString(option) }}
              <span
                >{{
                  calculatePercentage(question.survey_responses, option.id)
                }}%</span
              >
            </div>

            <div
              class="progress-bar tw-bg-lighter-grey tw-border tw-border-lightest-grey tw-relative"
            >
              <div
                class="tw-bg-lighter-blue"
                :style="{
                  width:
                    calculatePercentage(question.survey_responses, option.id) +
                    '%'
                }"
              ></div>
              <button
                v-if="
                  (isOwner || isOrganizer) &&
                  calculatePercentage(question.survey_responses, option.id) > 0
                "
                class="tw-absolute tw-top-1 tw-right-3 tw-text-xxs tw-text-dark-green tw-font-bold"
                @click="countResponses(question)"
              >
                SEE {{ getVotes(question, option) }}
                {{ getVotes(question, option) == 1 ? "VOTE" : "VOTES" }} >
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-for="(option, oIndex) in question.survey_options"
            :key="oIndex"
            class="option-result tw-mb-7"
          >
            <div
              class="tw-text-light-grey tw-text-sm tw-flex tw-flex-row tw-justify-between"
            >
              {{ option.text }}
              <span
                >{{
                  calculatePercentage(question.survey_responses, option.id)
                }}%</span
              >
            </div>

            <div
              class="progress-bar tw-bg-lighter-grey tw-border tw-border-lightest-grey tw-relative"
            >
              <div
                class="tw-bg-lighter-blue"
                :style="{
                  width:
                    calculatePercentage(question.survey_responses, option.id) +
                    '%'
                }"
              ></div>
              <button
                v-if="
                  (isOwner || isOrganizer) &&
                  calculatePercentage(question.survey_responses, option.id) > 0
                "
                class="tw-absolute tw-top-1 tw-right-3 tw-text-xxs tw-text-dark-green tw-font-bold"
                @click="countResponses(question)"
              >
                SEE {{ getVotes(question, option) }}
                {{ getVotes(question, option) == 1 ? "VOTE" : "VOTES" }} >
              </button>
              <button
                v-if="
                  !(isOwner || isOrganizer) &&
                  calculatePercentage(question.survey_responses, option.id) > 0
                "
                class="tw-absolute tw-top-1 tw-right-3 tw-text-xxs tw-text-dark-green tw-font-bold"
              >
                {{ getVotes(question, option) }}
                {{ getVotes(question, option) == 1 ? "VOTE" : "VOTES" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="isOpen" max-width="400" content-class="tw-rounded-3xl">
      <div class="tw-text-left tw-py-10 tw-pr-5 tw-pl-10 tw-bg-white">
        <div class="tw-max-h-96 tw-overflow-y-scroll">
          <div v-for="(response, index) in responseCounts" :key="index">
            <p
              class="tw-text-22 tw-font-semibold tw-text-charcoal tw-mb-0 tw-mt-8"
            >
              {{ getResponseValue(response) }}
            </p>
            <p class="tw-text-base tw-text-light-grey tw-font-normal tw-mb-5">
              {{ response.percentage }}% | {{ response.counter }} votes
            </p>
            <div class="tw-text-charcoal tw-text-sm" v-if="response.users">
              <div
                v-for="(user, index) in response.users"
                :key="index"
                class="tw-flex tw-flex-row tw-items-center tw-mb-4"
              >
                <UserAvatar :user="user" size="24" class="tw-mr-4" />
                <div>{{ user.fullName }}</div>
              </div>
            </div>
          </div>
        </div>

        <button
          class="btn-tertiary tw-block tw-mx-auto tw-mt-4"
          @click="isOpen = !isOpen"
        >
          Done
        </button>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { DateTime } from "luxon";

export default {
  name: "SurveyResults",
  components: {
    UserAvatar
  },
  mixins: [FormattedDateRange],
  data() {
    return {
      surveyData: null,
      currentUserHasResponded: null,
      userIdsHaveResponded: [],
      tripUsers: [],
      copyBtnLabel: "Copy link",
      isOpen: false,
      responseCount: null,
      selectedOptions: {},
      responseCounts: {},
      modalQuestion: null,
      dataLoaded: false
    };
  },
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    survey() {
      if (this.trip) {
        return this.$store.state["survey-builder"].keyedById[
          this.$route.params.surveyId
        ];
      } else return null;
    },
    isOwner() {
      return this.$store.state.auth.user.id === this.trip.ownerId;
    },
    isOrganizer() {
      return this.$store.state.auth.user.trips
        .find((t) => t.id === this.trip.id)
        .trip_invite.roles.includes("owner");
    }
  },
  methods: {
    isDateString(s) {
      try {
        DateTime.fromISO(s);
        return true;
      } catch (error) {
        return false;
      }
    },
    getVotes(question, option) {
      let count = 0;
      question.survey_responses.forEach((o) => {
        if (o.surveyOptionId === option.id) count++;
      });
      return count;
    },
    getResponseValue(response) {
      if (this.modalQuestion && this.modalQuestion.text === 'What date(s) work best?') {
        let dates = response.value.split(",");
        if (
          dates.length > 1 &&
          this.isDateString(dates[0]) &&
          this.isDateString(dates[1])
        ) {
          return `${this.getResponseDateFormattedValue(dates[0])} - ${this.getResponseDateFormattedValue(dates[1])}`;
        }
      } else {
        return response.value;
      }
    },
    async countResponses(question) {
      this.isOpen = true;
      this.modalQuestion = question;
      this.responseIndex = 0;
      this.responseCounts = [];
      this.userResponseMap = {};
      const totalResponses = question.survey_responses.length;

      question.survey_responses.forEach((response) => {
        const responseValue = response.value;
        const userId = response.userId;
        // Check if the response value already exists in the responseCounts array
        const existingResponse = this.responseCounts.find(
          (item) => item.value === responseValue
        );
        if (existingResponse) {
          existingResponse.counter++;
          existingResponse.userIds.push(userId);
        } else {
          // If not, create a new object and push it to the array
          this.responseCounts.push({
            value: responseValue,
            counter: 1,
            userIds: [userId],
            users: []
          });
        }
      });

      // Calculate and add percentages to the responseCounts
      this.responseCounts.forEach((response) => {
        response.percentage = Math.round(
          (response.counter / totalResponses) * 100
        );
      });

      //Fetch users from responseCounts
      this.responseCounts.forEach((response) => {
        response.userIds.forEach((id) => {
          const user = this.tripUsers.find((user) => user.id === id);
          if (user) {
            // If a user with the specified ID is found, push it into the response
            if (!response.users) {
              response.users = [];
            }
            response.users.push(user);
          }
        });
      });
    },
    sortedDates(options) {
      let dates = [...options].sort(
        (a, b) =>
          DateTime.fromISO(a.text.split(",")[0]).diff(
            DateTime.fromISO(b.text.split(",")[0])
          ).milliseconds
      );
      return dates;
    },
    userCompletedSurvey(userId) {
      return this.userIdsHaveResponded.includes(Number(userId));
    },
    fetchSurveyData() {
      this.$store
        .dispatch("survey-builder/get", this.$route.params.surveyId)
        .then((res) => {
          this.surveyData = res;
          this.$store.commit("meta/setHeader", {
            defaultHeaderDisabled: false,
            pageTitle: this.surveyData.title,
            bgColor: "#203848",
            fontColor: "#E6FFA1",
            tagline: null,
            iconOne: null,
            iconTwo: null,
            tripHubButton: false,
            showBackButton: true
          });

          this.currentUserHasResponded =
            this.surveyData.currentUserHasResponded;
          this.userIdsHaveResponded = this.surveyData.userIdsHaveResponded;
        })
        .catch((error) => {
          console.error("Error fetching survey data:", error);
        });
    },
    goTo(route) {
      this.$router.push(route);
    },
    calculatePercentage(responses, optionId) {
      if (!responses.length) return 0;

      // Count how many times this option was chosen
      const count = responses.filter(
        (response) => response.surveyOptionId === optionId
      ).length;

      // Calculate the percentage based on total responses
      return Math.round((count / responses.length) * 100);
    },
    copyLink() {
      if (navigator.clipboard) {
        const URL = window.location.href;
        const finalURL = URL.replace("survey-results", "survey");
        navigator.clipboard.writeText(finalURL);
      }

      this.copyBtnLabel = "Copied!";
      setTimeout(() => {
        this.copyBtnLabel = "Copy Link";
      }, 2000);
    },
    reorderAndStoreUsers() {
      // Find the index of the object with the specified ID
      this.tripUsers = this.trip.users;
      const targetId = this.$store.state.auth.user.id;
      const index = this.tripUsers.findIndex((obj) => obj.id === targetId);
      if (index !== -1) {
        // Splice the object out of its current position
        const [currentUser] = this.tripUsers.splice(index, 1);
        // Add the object back to the array at the first position
        this.tripUsers.unshift(currentUser);
      }
    }
  },

  async beforeMount() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });

    try {
      // Fetch trip data
      await this.$store.dispatch("trip/get", [
        this.$route.params.id,
        { query: { include: "destination,users,survey" } }
      ]);

      // Once trip data is fetched, get the survey data
      await this.fetchSurveyData();
      this.reorderAndStoreUsers();

      this.dataLoaded = true;
    } catch (error) {
      console.error("Error:", error);
    }
  },
  updated() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });
  },
  destroyed() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: false
    });
  }
};
</script>

<style lang="scss" scoped>
.greyed-out {
  filter: grayscale(100%);
  opacity: 0.6;
}

.avatars-container {
  display: flex;
  align-items: center;
  gap: 16px;
  overflow-x: auto;
  white-space: nowrap;
}

.avatar-wrapper > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatars-container::-webkit-scrollbar {
  display: none;
}
.avatars-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.avatar-wrapper img {
  width: 100%;
  height: auto;
}

.progress-bar {
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 20px;
  overflow: hidden;
}

.progress-bar > div {
  height: 100%;
  background-color: #3498db;
}

.v-chip.v-size--default {
  height: auto;
  background-color: transparent !important;
}

.v-chip,
.v-chip:active,
.v-chip:focus,
.v-chip:hover {
  background: none !important;
  margin: 0 !important;
}

.v-chip:active::before,
.v-chip:hover::before,
.v-chip:focus::before,
.theme--light.v-chip--active:hover::before,
.theme--light.v-chip--active::before {
  opacity: 0 !important;
}

.v-chip--clickable:active {
  box-shadow: none !important;
}

.v-chip .v-avatar {
  width: 3.5rem !important;
  height: 3.5rem !important;
}
</style>

<style>
.v-slide-group__next--disabled,
.v-slide-group__prev--disabled {
  pointer-events: none;
  display: none;
}
</style>
