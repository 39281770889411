<template>
  <div class="survey-view">
    <div class="survey-view-respond" v-if="survey && !isSurveyLoading">
      <v-row class="mb-4">
        <v-col cols="12">
          <v-row>
            <v-col cols="6" class="text-left pb-0">
              <h5>Survey Progress</h5>
            </v-col>
            <v-spacer />
            <v-col cols="4" class="text-right pb-0">
              <h5>{{ surveyProgress }}%</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-progress-linear
                rounded
                color="secondary"
                height="10"
                v-if="survey.questions"
                :value="surveyProgress"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div
        class="survey-step"
        v-for="(question, i) in survey.questions"
        :key="question.id"
      >
        <v-col cols="12" v-if="currentStep === i + 1">
          <v-row
            class="pb-10"
            v-if="question.text === 'What date(s) work best?'"
          >
            <v-col cols="12" class="mb-6">
              <h3 class="tw-text-22 tw-font-semibold tw-text-left">
                {{ question.text }}
              </h3>
              <p class="tw-text-light-grey tw-text-left">
                Select as many as you'd like, or add other.
              </p>
            </v-col>
            <v-row>
              <v-col cols="12" class="py-1">
                <v-row align="center">
                  <div class="survey-option-list">
                    <div
                      class="tw-w-full tw-flex tw-items-center"
                      v-for="option in sortedDates(question.survey_options)"
                      :key="option.id"
                    >
                      <div
                        class="survey-option-item j-shadow-override"
                        :class="{
                          'survey-option-item--selected': checkIsSelected(
                            option.id
                          )
                        }"
                        ripple
                        :value="getFormattedDateRangeFromString(option)"
                        @click="selectSurveyOption(option)"
                        rounded
                        block
                        x-large
                        elevation="3"
                      >
                        {{ getFormattedDateRangeFromString(option) }}
                      </div>

                      <v-btn
                        class="tw-w-10 tw-h-10"
                        icon
                        v-if="!inInitialResponse(option)"
                      >
                        <v-icon
                          size="20"
                          class="tw-text-charcoal tw--ml-2 tw--mt-2"
                          @click="deleteSurveyOption(option.id)"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </div>
                  </div>
                  <v-expansion-panels
                    v-if="addOtherDates"
                    :ref="'datepickerPanel_0'"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div
                          class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                        >
                          <v-icon>mdi-calendar-blank</v-icon>
                          <div
                            class="tw-text-dark-green tw-text-base tw-font-semibold tw-mx-auto"
                          >
                            <span v-if="addOtherDates">{{
                              getFormattedDateRange(
                                addOtherDates[0],
                                addOtherDates[1]
                              )
                            }}</span>
                            <span v-else>Select a date range</span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-date-picker
                          class="tw-py-4"
                          v-model="addOtherDates"
                          range
                          no-title
                          color="secondary"
                          @change="closePanel(question, 0)"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <template v-if="question.addOptionAllowed">
                    <p
                      class="tw-text-dark-green tw-text-center tw-w-full tw-mt-6 tw-font-semibold tw-underline tw-cursor-pointer"
                      @click="addOption(question)"
                      v-if="!addOtherDates"
                    >
                      Add other
                    </p>
                    <p
                      class="tw-text-dark-green tw-text-center tw-w-full tw-mt-6 tw-font-semibold tw-underline tw-cursor-pointer"
                      @click="addOtherDates = null"
                      v-else
                    >
                      Cancel
                    </p>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-row>
          <v-row class="pb-10" v-else>
            <v-col cols="12" class="mb-6">
              <h3 class="tw-text-22 tw-font-semibold tw-text-left">
                {{ question.text }}
              </h3>
              <p
                class="tw-text-light-grey tw-text-left"
                v-if="question.text === 'What vibes are you looking for?'"
              >
                Select as many as you'd like.
              </p>
              <p
                class="tw-text-light-grey tw-text-left"
                v-if="question.text === 'Where would you like to go?'"
              >
                Select as many as you'd like, or add other.
              </p>
            </v-col>
            <v-row>
              <v-col cols="12" class="py-1">
                <v-row align="center">
                  <div class="survey-option-list">
                    <div
                      class="tw-w-full tw-flex tw-items-center"
                      v-for="option in question.survey_options"
                      :key="option.id"
                    >
                      <div
                        class="survey-option-item j-shadow-override justify-center"
                        :class="{
                          'survey-option-item--selected': checkIsSelected(
                            option.id
                          )
                        }"
                        ripple
                        :value="option.text"
                        @click="selectSurveyOption(option)"
                        rounded
                        block
                        x-large
                        elevation="3"
                      >
                        <span>{{ option.text }}</span>
                      </div>
                      <v-btn
                        class="tw-w-10 tw-h-10"
                        icon
                        v-if="!inInitialResponse(option)"
                      >
                        <v-icon
                          size="20"
                          class="tw-text-charcoal tw--ml-2 tw--mt-2"
                          @click="deleteSurveyOption(option.id)"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </div>

                    <div v-if="addOtherText" class="tw-w-full">
                      <j-text-field
                        v-model="addOtherText"
                        elevation="1"
                        light
                        background-color="#fafafa"
                        color="#203848"
                        class="tw-w-full"
                        hide-details
                        placeholder="Add Option"
                        @blur="addNewTextOption(question)"
                      />
                    </div>
                  </div>
                  <template v-if="question.addOptionAllowed">
                    <p
                      class="tw-text-dark-green tw-text-center tw-w-full tw-mt-6 tw-font-semibold tw-underline tw-cursor-pointer"
                      @click="addOption(question)"
                      v-if="!addOtherText"
                    >
                      Add other
                    </p>
                    <p
                      class="tw-text-dark-green tw-text-center tw-w-full tw-mt-6 tw-font-semibold tw-underline tw-cursor-pointer"
                      @click="addOtherText = null"
                      v-else
                    >
                      Cancel
                    </p>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </div>
      <v-row justify="center">
        <v-col cols="12">
          <v-btn
            x-large
            rounded
            color="primary"
            class="tw-mx-auto tw-w-3/4 j-shadow-override tw-tracking-normal"
            v-if="currentStep + 1 <= survey.questions.length"
            @click="addResponse"
            :disabled="!canSubmit"
            >Next</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn
            class="tw-mx-auto tw-w-3/4 j-shadow-override tw-tracking-normal"
            x-large
            rounded
            color="primary"
            v-if="currentStep + 1 > survey.questions.length"
            @click="submitResponses"
            :disabled="!canSubmit"
            >Submit</v-btn
          >
        </v-col>
        <v-col cols="12" v-if="currentStep > 1">
          <v-btn
            depressed
            class="tw-mx-auto tw-w-3/4 tw-bg-white tw-border tw-border-solid tw-border-black tw-text-dark-green hover:tw-bg-white j-btn-outline tw-tracking-normal"
            x-large
            rounded
            @click="showPrevious"
            >Previous Question</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <div class="survey-view-loading" v-if="isSurveyLoading">
      <map-icon-loader />
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import MapIconLoader from "@/components/loaders/MapIconLoader";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
export default {
  name: "SurveyView",

  components: {
    MapIconLoader
  },
  mixins: [FormattedDateRange],
  data() {
    return {
      addOtherDates: null,
      addOtherText: null,
      currentStep: 1,
      selectedMultipleSurveyOptionIds: [],
      selectedMultipleSurveyOptions: [],
      selectedSurveyOptionId: null,
      selectedSurveyOption: null,
      responseArray: [],
      initialQuestionResponses: []
    };
  },
  watch: {
    currentStep(val) {
      this.addInitialQuestionResponses(val);
    }
  },
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    survey() {
      if (this.trip) {
        return this.$store.state["survey-builder"].keyedById[
          this.$route.params.surveyId
        ];
      } else return null;
    },
    isOwner() {
      return this.trip.ownerId === this.$store.state.auth.user.id;
    },
    isSurveyLoading() {
      return this.$store.state["survey-builder"].isGetPending;
    },
    surveyProgress() {
      if (this.survey) {
        const percentage =
          (this.currentStep / this.survey.questions.length) * 100;
        return Math.round(percentage);
      } else {
        return 0;
      }
    },
    canSubmit() {
      return (
        this.selectedSurveyOptionId !== null ||
        this.selectedMultipleSurveyOptionIds.length > 0
      );
    }
  },

  methods: {
    inInitialResponse(option) {
      let current = this.survey.questions[this.currentStep - 1];
      return this.initialQuestionResponses.some(
        (r) => r.surveyQuestionId === current.id && r.id === option.id
      );
    },
    addInitialQuestionResponses(step) {
      let current = this.survey.questions[step - 1];
      this.initialQuestionResponses = current.survey_options;
    },
    async deleteSurveyOption(id) {
      let current = this.survey.questions[this.currentStep - 1];
      current.survey_options = current.survey_options.filter(
        (r) => r.id !== id
      );
      await this.$store.dispatch("survey-option/remove", id);
    },
    showAddOtherOption(q) {
      if (
        q.text === "What date(s) work best?" ||
        q.text === "Where would you like to go?"
      )
        return true;
      return false;
    },
    addNewTextOption(question) {
      if (this.addOtherText.trim().length > 0) {
        this.createOption(question, this.addOtherText);
        this.addOtherText = "";
      }
    },
    closePanel(question, i) {
      const panelRef = this.$refs["datepickerPanel_" + i][0];
      this.createOption(question, this.addOtherDates);
      if (panelRef) panelRef.internalValue = undefined;
    },
    addOption(question) {
      if (question.text === "What date(s) work best?") {
        this.addOtherDates = [];
      } else {
        this.addOtherText = " ";
      }
    },
    createOption(question, newOption) {
      // Check if newOption is an array and join its elements
      if (Array.isArray(newOption)) {
        if (newOption && newOption[0] && newOption[1]) {
          let temp = [...newOption];
          if (new Date(newOption[0]) > new Date(newOption[1])) {
            newOption[0] = temp[1];
            newOption[1] = temp[0];
          }
        }
        newOption = newOption.join(",");
      }
      const currentTime = DateTime.local();
      const option = {
        surveyId: this.$route.params.surveyId,
        surveyQuestionId: question.id,
        text: newOption,
        createdAt: currentTime.toISO(),
        updatedAt: currentTime.toISO()
      };
      if (option.text) {
        this.$store.dispatch("survey-option/create", option).then(() => {
          this.$store.dispatch(
            "survey-builder/get",
            this.$route.params.surveyId
          );
          this.newOption = null;
        });
      }
      this.addOtherDates = null;
    },
    sortedDates(options) {
      let dates = [...options].sort(
        (a, b) =>
          DateTime.fromISO(a.text.split(",")[0]).diff(
            DateTime.fromISO(b.text.split(",")[0])
          ).milliseconds
      );
      return dates;
    },
    selectSurveyOption(option) {
      const currentQuestion = this.survey.questions[this.currentStep - 1];
      if (currentQuestion.allowMultipleAnswers) {
        if (this.selectedMultipleSurveyOptionIds.includes(option.id)) {
          this.selectedMultipleSurveyOptionIds =
            this.selectedMultipleSurveyOptionIds.filter(
              (id) => id !== option.id
            );
          this.selectedMultipleSurveyOptions.filter(
            (opt) => opt.id !== option.id
          );
        } else {
          this.selectedMultipleSurveyOptionIds.push(option.id);
          this.selectedMultipleSurveyOptions.push(option);
        }
      } else {
        this.selectedSurveyOptionId = option.id;
        this.selectedSurveyOption = option;
      }
    },
    checkIsSelected(id) {
      const currentQuestion = this.survey.questions[this.currentStep - 1];
      if (currentQuestion.allowMultipleAnswers) {
        return this.selectedMultipleSurveyOptionIds.includes(id);
      } else {
        return id === this.selectedSurveyOptionId;
      }
    },
    showPrevious() {
      this.currentStep = this.currentStep - 1;
      this.responseArray.splice(this.currentStep - 1, 1);
    },
    addResponse() {
      const currentQuestion = this.survey.questions[this.currentStep - 1];
      if (currentQuestion.allowMultipleAnswers) {
        this.selectedMultipleSurveyOptions.forEach((opt) => {
          this.responseArray.push({
            userId: this.$store.state.auth.user.id,
            tripId: this.$route.params.id,
            surveyId: this.survey.id,
            surveyQuestionId: currentQuestion.id,
            surveyOptionId: opt.id,
            value: opt.text
          });
        });
        this.selectedMultipleSurveyOptionIds = [];
        this.selectedMultipleSurveyOptions = [];
      } else {
        this.responseArray.push({
          userId: this.$store.state.auth.user.id,
          tripId: this.$route.params.id,
          surveyId: this.survey.id,
          surveyQuestionId: currentQuestion.id,
          surveyOptionId: this.selectedSurveyOptionId,
          value: this.selectedSurveyOption.text
        });
        this.selectedSurveyOptionId = null;
      }
      this.currentStep++;
    },
    submitResponses() {
      const currentQuestion = this.survey.questions[this.currentStep - 1];
      if (currentQuestion.allowMultipleAnswers) {
        this.selectedMultipleSurveyOptions.forEach((opt) => {
          this.responseArray.push({
            userId: this.$store.state.auth.user.id,
            tripId: this.$route.params.id,
            surveyId: this.survey.id,
            surveyQuestionId: currentQuestion.id,
            surveyOptionId: opt.id,
            value: opt.text
          });
        });
      } else {
        this.responseArray.push({
          userId: this.$store.state.auth.user.id,
          tripId: this.$route.params.id,
          surveyId: this.survey.id,
          surveyQuestionId: currentQuestion.id,
          surveyOptionId: this.selectedSurveyOptionId,
          value: this.selectedSurveyOption.text
        });
      }
      this.$store
        .dispatch("survey-response/create", [this.responseArray, {}])
        .then(() => {
          this.selectedSurveyOptionId = null;
          this.selectedMultipleSurveyOptionIds = [];
          this.selectedMultipleSurveyOptions = [];
          const routeName = sessionStorage.getItem("fromInvitation")
            ? "RSVPConfirmationYes"
            : "SurveyCompleteRoute";
          sessionStorage.removeItem("fromInvitation");
          this.$router.push({ name: routeName });
        });
    }
  },

  async beforeMount() {
    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    const surveyResponse = await this.$store.dispatch(
      "survey-builder/get",
      this.$route.params.surveyId
    );

    if (surveyResponse && surveyResponse.currentUserHasResponded)
      this.$router.push({ name: "SurveyResults" });

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: this.trip.title,
      bgColor: "#203848",
      fontColor: "#E6FFA1",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    surveyResponse.questions.forEach((question) => {
      // Sort the survey_options array by id
      question.survey_options.sort((a, b) => a.id - b.id);
    });

    let myResponse = null;
    let user = this.trip.users.find(
      (user) => user.id === this.$store.state.auth.user.id
    );

    if (user && user.trip_invite) {
      myResponse = user.trip_invite.status;
    }

    let hasDates = false;
    if (this.trip.startDate && this.trip.endDate) hasDates = true;

    const now = DateTime.now();
    const start = DateTime.fromISO(this.$store.state.auth.user.createdAt);
    const diff = start.diff(now, "days").toObject();
    const count = Math.abs(Math.round(diff.days));
    let newUser = false;
    if (count <= 7) {
      newUser = true;
    }

    window.dataLayer.push({
      event: "take_trip_survey",
      uuid: this.$store.state.auth.user
        ? this.$store.state.auth.user.uuid
        : null,
      signup_date: this.$store.state.auth.user
        ? DateTime.fromISO(this.$store.state.auth.user.createdAt).toISO()
        : null,
      new_user: newUser,
      verified_user: this.$store.state.auth.user
        ? this.$store.state.auth.user.isEmailVerified
        : false,
      sign_up_trip_id: this.$store.state.auth.user
        ? this.$store.state.auth.user.signupTripId
        : null,
      trip_id: this.trip.id,
      owner_id: this.trip.ownerId,
      trip_invite_id: this.trip.trip_invitation
        ? this.trip.trip_invitation.id
        : null,
      is_owner: this.isOwner,
      has_dates: hasDates,
      has_destination:
        this.trip.destinations && this.trip.destinations.length > 0,
      response_deadline: this.trip.trip_invitation
        ? this.trip.trip_invitation.rsvpDeadline
        : null,
      timestamp: DateTime.now().toISO(),
      rsvp_response: myResponse,
      survey_id: this.survey.id
    });

    //add initial responses
    this.addInitialQuestionResponses(this.currentStep);
  }
};
</script>

<style lang="scss">
.theme--light.v-btn:hover::before {
  opacity: 0;
}

.survey-option-list {
  @apply tw-flex tw-flex-wrap tw-w-full tw-py-0 tw-px-4;

  .survey-option-item {
    @apply tw-flex tw-w-full tw-bg-lighter-grey tw-rounded-30 tw-py-4 tw-px-8 tw-mb-3 tw-text-center tw-font-semibold tw-cursor-pointer;
    flex: 0 0 100%;
  }

  .survey-option-item--selected {
    @apply tw-bg-dark-green tw-text-white;
  }
}
</style>
