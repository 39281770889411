var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataLoaded)?_c('div',{staticClass:"tw-mb-10"},[(_vm.isOwner || _vm.isOrganizer)?_c('v-row',{staticClass:"tw-pb-10 text-left"},[(_vm.survey)?_c('v-col',{staticClass:"tw-px-9"},[_c('h3',{staticClass:"tw-text-25 tw-text-dark-green tw-font-semibold"},[_vm._v(" Your trip survey ")]),_c('p',{staticClass:"tw-text-light-grey tw-text-base tw-leading-22"},[_vm._v(" You can add or remove questions to your survey until a response is submitted. ")]),_c('v-expansion-panels',{staticClass:"tw-rounded-md tw-mb-6"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"j-panel-header"},[_c('div',{staticClass:"tw-text-dark-green tw-text-base tw-font-semibold tw-mx-auto"},[_vm._v(" View/Edit Survey ")])])]),_c('v-expansion-panel-content',{staticClass:"j-panel-content"},[_vm._l((_vm.survey.questions),function(question,index){return _c('div',{key:index,staticClass:"tw-px-6 tw-pb-4"},[(question.text === 'What date(s) work best?')?_c('div',[_c('p',{staticClass:"j-invitation-label tw-mb-1"},[_vm._v(_vm._s(question.text))]),_c('div',{staticClass:"tw-bg-lighter-grey tw-rounded-md tw-py-6 tw-px-5 tw-mb-5"},_vm._l((_vm.sortedDates(
                      question.survey_options
                    )),function(option,index){return _c('p',{key:index,staticClass:"j-invitation-paragraph tw-mb-0"},[_vm._v(" "+_vm._s(_vm.getFormattedDateRangeFromString(option))+" ")])}),0)]):_c('div',[_c('p',{staticClass:"j-invitation-label tw-mb-1"},[_vm._v(_vm._s(question.text))]),_c('div',{staticClass:"tw-bg-lighter-grey tw-rounded-md tw-py-6 tw-px-5 tw-mb-5"},_vm._l((question.survey_options),function(option,index){return _c('p',{key:index,staticClass:"j-invitation-paragraph tw-mb-0"},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)])])}),_c('router-link',{staticClass:"tw-bg-dark-green tw-text-white tw-p-5 tw-text-center tw-rounded-b-md tw-font-semibold tw-block tw-w-full",attrs:{"to":{
                name: 'SurveyEditRouteForm',
                params: {
                  surveyId: _vm.$route.params.surveyId
                }
              }}},[_vm._v(" Edit Survey ")])],2)],1)],1)],1):_vm._e()],1):_vm._e(),((_vm.isOwner || _vm.isOrganizer) && !_vm.currentUserHasResponded)?_c('div',{staticClass:"tw-bg-separator-grey tw-w-full tw-h-2"}):_vm._e(),(!_vm.currentUserHasResponded)?_c('div',{staticClass:"tw-py-10 tw-px-9"},[_c('h3',{staticClass:"tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-4"},[_vm._v(" Your Voice Counts Too ")]),_c('p',{staticClass:"tw-text-left tw-text-light-grey"},[_vm._v(" There's a survey with your name on it. Your responses will be included in the results. ")]),_c('v-btn',{staticClass:"j-btn tw-bg-chartreuse tw-text-dark-green tw-tracking-normal",attrs:{"x-large":"","rounded":"","block":"","depressed":""},on:{"click":function($event){return _vm.goTo({
          name: 'SurveyRoute',
          params: { surveyId: _vm.$route.params.surveyId }
        })}}},[_vm._v("Take Survey")])],1):_vm._e(),(_vm.isOwner || _vm.isOrganizer || !_vm.currentUserHasResponded)?_c('div',{staticClass:"tw-bg-separator-grey tw-w-full tw-h-2"}):_vm._e(),_c('div',{staticClass:"tw-px-9 tw-py-12 tw-rounded-md",class:{ 'tw--mt-8': !(_vm.isOwner || _vm.isOrganizer) }},[_c('h3',{staticClass:"tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-4"},[_vm._v(" Survey Responses ")]),_c('v-chip-group',_vm._l((_vm.tripUsers),function(user,i){return _c('v-chip',{key:i,attrs:{"value":user,"ripple":false}},[_c('UserAvatar',{class:{ 'greyed-out': !_vm.userCompletedSurvey(user.id) },attrs:{"user":user,"size":"56"}}),(
            _vm.userIdsHaveResponded && _vm.userIdsHaveResponded.includes(user.id)
          )?_c('img',{staticClass:"tw-absolute tw-w-4 tw-h-4 tw-bottom-0 tw-right-3",attrs:{"src":require("@/assets/svgs/surveys/avatar-checkmark.svg"),"alt":"Checkmark"}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"tw-bg-separator-grey tw-w-full tw-h-2"}),(_vm.surveyData && _vm.surveyData.questions)?_c('div',{staticClass:"tw-px-9 tw-py-12 tw-rounded-md"},[_c('h3',{staticClass:"tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-8"},[_vm._v(" Survey Results ")]),_vm._l((_vm.surveyData.questions),function(question,qIndex){return _c('div',{key:qIndex,staticClass:"tw-rounded-md tw-bg-white tw-p-6 tw-mb-5 tw-shadow-custom"},[_c('h3',{staticClass:"tw-text-left tw-font-semibold tw-mb-4"},[_vm._v(" "+_vm._s(question.text)+" ")]),(question.text === 'What date(s) work best?')?_c('div',_vm._l((_vm.sortedDates(question.survey_options)),function(option,oIndex){return _c('div',{key:oIndex,staticClass:"option-result tw-mb-7"},[_c('div',{staticClass:"tw-text-light-grey tw-text-sm tw-flex tw-flex-row tw-justify-between"},[_vm._v(" "+_vm._s(_vm.getFormattedDateRangeFromString(option))+" "),_c('span',[_vm._v(_vm._s(_vm.calculatePercentage(question.survey_responses, option.id))+"%")])]),_c('div',{staticClass:"progress-bar tw-bg-lighter-grey tw-border tw-border-lightest-grey tw-relative"},[_c('div',{staticClass:"tw-bg-lighter-blue",style:({
                width:
                  _vm.calculatePercentage(question.survey_responses, option.id) +
                  '%'
              })}),(
                (_vm.isOwner || _vm.isOrganizer) &&
                _vm.calculatePercentage(question.survey_responses, option.id) > 0
              )?_c('button',{staticClass:"tw-absolute tw-top-1 tw-right-3 tw-text-xxs tw-text-dark-green tw-font-bold",on:{"click":function($event){return _vm.countResponses(question)}}},[_vm._v(" SEE "+_vm._s(_vm.getVotes(question, option))+" "+_vm._s(_vm.getVotes(question, option) == 1 ? "VOTE" : "VOTES")+" > ")]):_vm._e()])])}),0):_c('div',_vm._l((question.survey_options),function(option,oIndex){return _c('div',{key:oIndex,staticClass:"option-result tw-mb-7"},[_c('div',{staticClass:"tw-text-light-grey tw-text-sm tw-flex tw-flex-row tw-justify-between"},[_vm._v(" "+_vm._s(option.text)+" "),_c('span',[_vm._v(_vm._s(_vm.calculatePercentage(question.survey_responses, option.id))+"%")])]),_c('div',{staticClass:"progress-bar tw-bg-lighter-grey tw-border tw-border-lightest-grey tw-relative"},[_c('div',{staticClass:"tw-bg-lighter-blue",style:({
                width:
                  _vm.calculatePercentage(question.survey_responses, option.id) +
                  '%'
              })}),(
                (_vm.isOwner || _vm.isOrganizer) &&
                _vm.calculatePercentage(question.survey_responses, option.id) > 0
              )?_c('button',{staticClass:"tw-absolute tw-top-1 tw-right-3 tw-text-xxs tw-text-dark-green tw-font-bold",on:{"click":function($event){return _vm.countResponses(question)}}},[_vm._v(" SEE "+_vm._s(_vm.getVotes(question, option))+" "+_vm._s(_vm.getVotes(question, option) == 1 ? "VOTE" : "VOTES")+" > ")]):_vm._e(),(
                !(_vm.isOwner || _vm.isOrganizer) &&
                _vm.calculatePercentage(question.survey_responses, option.id) > 0
              )?_c('button',{staticClass:"tw-absolute tw-top-1 tw-right-3 tw-text-xxs tw-text-dark-green tw-font-bold"},[_vm._v(" "+_vm._s(_vm.getVotes(question, option))+" "+_vm._s(_vm.getVotes(question, option) == 1 ? "VOTE" : "VOTES")+" ")]):_vm._e()])])}),0)])})],2):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400","content-class":"tw-rounded-3xl"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"tw-text-left tw-py-10 tw-pr-5 tw-pl-10 tw-bg-white"},[_c('div',{staticClass:"tw-max-h-96 tw-overflow-y-scroll"},_vm._l((_vm.responseCounts),function(response,index){return _c('div',{key:index},[_c('p',{staticClass:"tw-text-22 tw-font-semibold tw-text-charcoal tw-mb-0 tw-mt-8"},[_vm._v(" "+_vm._s(_vm.getResponseValue(response))+" ")]),_c('p',{staticClass:"tw-text-base tw-text-light-grey tw-font-normal tw-mb-5"},[_vm._v(" "+_vm._s(response.percentage)+"% | "+_vm._s(response.counter)+" votes ")]),(response.users)?_c('div',{staticClass:"tw-text-charcoal tw-text-sm"},_vm._l((response.users),function(user,index){return _c('div',{key:index,staticClass:"tw-flex tw-flex-row tw-items-center tw-mb-4"},[_c('UserAvatar',{staticClass:"tw-mr-4",attrs:{"user":user,"size":"24"}}),_c('div',[_vm._v(_vm._s(user.fullName))])],1)}),0):_vm._e()])}),0),_c('button',{staticClass:"btn-tertiary tw-block tw-mx-auto tw-mt-4",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_vm._v(" Done ")])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }